.notifications {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  &__container {
    position: absolute;
    top: 0;
    right: 0;
    width: 460px;
    box-shadow: 0px 4px 10px rgba(14, 13, 91, 0.16);
    overflow-y: auto;
    height: 100%;
    background: #fff;
  }
  &__title {
    background: #fff;
  }
  &__content {
    height: calc(100% - 60px);
    padding: 10px 25px;
  }

  &__loader-wrapper {
    height: 90vh;
  }

  &__footer {
    height: 60px;
    display: flex;
    justify-content: center;
  }

  &__no-items {
    margin-top: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.notifications-filter {

  &__container {
    display: flex;
    align-items: center;
  }

  &__item {
    font-family: 'Open Sans', 'sans-serif';
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(14, 13, 91, 1);
  }
}
