@import "../../../styles/letterType";

.create-household-info {
  width: 100%;
  height: 100%;
  background: #fff;
  //   overflow-y: auto;
}

.create-household-info:not(:first-child) {
  margin-top: 12px;
}

.create-household-info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(197, 202, 227, 1);
}

.create-household-info__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  //width: 100px;
}

.create-household-info__header-radio {
  margin-right: 10px;
}

.create-household-info__header-form-control {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  gap: 10px;
}

.create-household-info__header-form-control-radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-household-info__header-form-control-radio-checked{
  background-color: rgba(14, 13, 91, 1);
  -webkit-appearance: none;
  appearance: none;
    margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}


.create-household-info__header-form-control-radio::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgba(14, 13, 91, 1);
  display: none;
}

.create-household-info__header-form-control-radio:checked::before {
  display: block;
}

.create-household-info__header-container-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border: 1px solid rgba(37, 36, 94, 0.1);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.create-household-info__header-container-chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.create-household-info__container {
  padding: 15px 20px;
}

.create-household-info__title-panel {
  padding-top: 40px;
  margin-bottom: 25px;
}

.create-household-info__address-button {
  margin-bottom: 40px;
}

.create-household-info__contact-info {
  margin-bottom: 30px;
}

.create-household-info__header-container-delete_hidden {
  visibility: hidden;
}

.create-household-info__container_hidden {
  display: none;
}
.create-household-info__header-container-chevron_open {
  transform: rotate(180deg);
}
