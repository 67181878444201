@import "../../../styles/letterType";

.calendar__input-container {
  display: flex;
  justify-content: center;
}

.calendar__input {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(14, 13, 91, 1);
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  cursor: pointer;
  margin-bottom: 1px;
}

.calendar__input-label {
  position: absolute;
  top: -12px;
  left: 15px;
  background: #fff;
  padding: 0 5px;
  border-radius: 6px;
}
