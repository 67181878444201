@import "../../../styles/letterType";

.visit-report-add-address-button {
  height: 40px;
  border-radius: 24px;
  background: #fff;
  color: rgba(14, 13, 91, 1);
  border: 2px solid rgba(14, 13, 91, 1);
  width: 100%;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  padding: 10px 15px;
  cursor: pointer;
}
