@import "../../../styles/letterType";

.create-household-layout-button__button {
  height: 50px;
  background: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 5px;
  cursor: pointer;

  &.isDisabled {
    pointer-events: none;
  }
}

.create-household-layout-button__button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-household-layout-button__button-image {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: rgba(246, 247, 252, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.create-household-layout-button__button-title {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);

  &.delete-title {
    color: #EE4E4E;
  }
}

.create-household-layout-button__button-subtitle {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  opacity: 0.5;
}

.create-household-layout-button_active {
  background: rgba(246, 247, 252, 1);
}
