@import "../../styles/letterType";

// .contacts {
//   padding: 0 25px;
// }

// .contacts__container {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 70px;
//   margin-bottom: 65px;

//   h2 {
//     font-family: $type-two;
//     font-size: 22px;
//     font-weight: 600;
//     line-height: 33px;
//     letter-spacing: 0.02em;
//     color: rgba(14, 13, 91, 1);
//   }
// }

// .contacts__link-container {
//   min-width: 140px;
//   height: 15px;
//   display: flex;
//   justify-content: space-between;
// }

// .contacts__link-container a {
//   font-family: $type-one;
//   font-size: 11px;
//   font-weight: 500;
//   line-height: 15px;
//   letter-spacing: 0.02em;
//   text-align: left;
//   text-decoration: none;
// }

// .contacts__link-dashboard {
//   color: rgba(14, 13, 91, 1);
// }

// .contacts__link-contacts {
//   color: rgba(157, 167, 199, 1);
// }

// .contacts__filters-container {
//   display: flex;
//   justify-content: space-between;
//   height: 40px;
//   margin-bottom: 25px;
// }

// .contacts__filters-container-inner {
//   display: flex;
//   gap: 15px;
// }

// .contacts__pagination {
//   display: flex;
//   padding-top: 15px;
// }

// .contacts__paginator-container {
//   margin-left: auto;
// }


.team {
  padding: 21px 25px;
}

.contacts__header-container {
  display: flex;
}

.team__button{
  margin-left: 30px;
}