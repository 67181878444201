@import "../../../styles/letterType";

.login-page-input {
  position: relative;
}

.login-page-input__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(106, 106, 153, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.login-page-input__image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: 20px;
  cursor: pointer;
}

.login-page-input__input {
  position: relative;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
}

.login-page-input__input:focus {
  outline: none;
  border: 2px solid rgba(14, 13, 91, 1);
  padding-left: 19px;
}

.login-page-input__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  // line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  line-height: 50px;
}

.login-page-input__input_error {
  border: 1px solid rgba(238, 78, 78, 1);
  padding-left: 20px;
}

.login-page-input__input_error:focus {
  border: 1px solid rgba(238, 78, 78, 1);
  padding-left: 20px;
}

.login-page-input__error-label {
  position: absolute;
  left: 2px;
  bottom: -20px;

  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(238, 78, 78, 1);
}
