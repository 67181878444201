@import "../../../../../styles/letterType";

.contacts__export-children {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.contacts__export-children-image-second {
  position: absolute;
  top: 90px;
  transform: rotate(180deg);
}
.contacts__export-change-container {
  display: flex;
  flex-direction: column;
}

.contacts__export-children-title {
  font-family: $type-two;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(14, 13, 91, 1);
  padding-top: 50px;
}
