@import "../../../styles/letterType";

.visit-report-warning-panel {
  padding: 5px 10px;
  height: 36px;
  background: rgba(252, 244, 230, 1);
  border: 1px solid rgba(250, 235, 205, 1);
  border-radius: 5px;
}

.visit-report-warning-panel__container {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(178, 140, 71, 1);
  gap: 15px;

  display: flex;
  align-items: center;
}
