@import "../../../styles/letterType";

.scheduled-visit-panel {
  display: flex;
  justify-content: space-between;
  width: 55%;
}

.scheduled-visit-panel__visit-container {
  display: flex;
  gap: 10px;
}

.scheduled-visit-panel__date-title {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 0.5);
}

.scheduled-visit-panel__date {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 1);
}
