@import "../../../styles/letterType";

.saved-routes-list-header {
  background: rgba(246, 247, 252, 1);
  // border: 1px solid rgba(197, 202, 227, 1);
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saved-routes-list-header__text {
  font-family: $type-two;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(14, 13, 91, 1);
}

.contacts-list__wrpr{
  // border: 1px solid rgba(197, 202, 227, 1);
  border-top: 1px solid rgba(197, 202, 227, 1);
  border-right: 1px solid rgba(197, 202, 227, 1);
  border-left: 1px solid rgba(197, 202, 227, 1);
}
