@import "../../../styles/letterType";

.routes-page-button {
  height: 40px;
  border-radius: 24px;
  background: rgba(14, 13, 91, 1);
  color: #fff;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  padding: 10px 35px;
  cursor: pointer;
}

.routes-page-button__image {
  padding-right: 5px;
}
