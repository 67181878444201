.empty-state-contacts {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 27px;
    font-weight: 600;
    margin-top: 40px;
    color: #0E0D5B;
    font-family: 'Poppins', sans-serif;
  }

  &__subtitle {
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    color: #0E0D5B;
    font-family: 'Poppins', sans-serif;
  }
}