@import "../../../styles/letterType";

.home-page-route-header {
  height: 60px;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid rgba(230, 230, 235, 1);
  display: flex;
  align-items: center;
}

.home-page-route-header__container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-page-route-header__title {
  font-family: $type-two;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.home-page-route-header__cross {
  margin-left: auto;
  cursor: pointer;
}
