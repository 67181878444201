.saved-routes-page__header {
  margin-bottom: 40px;
}

.saved-routes-page__container {
  padding: 0 25px;
}

.saved-routes-page__footer {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  margin-bottom: 40px;
}
