@import "../../../styles/letterType";

.contacts-page-sort-filter {
  position: relative;
  z-index: 10;

  &.isDisabled {
    pointer-events: none;
  }
}

.contacts-page-sort-filter__header {
  height: 32px;
  background: #fff;
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(106, 106, 153, 1);
  border: 1px solid rgba(106, 106, 153, 0.6);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 10px;
  cursor: pointer;

  &.isDisabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
}

.contacts-page-sort-filter__img {
  opacity: 0.6;
}

.contacts-page-sort-filter__container {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding: 15px 0px;

  background: #fff;
  border: 1px solid rgba(230, 230, 235, 0.25);
  box-shadow: 0px 4px 10px rgba(14, 13, 91, 0.16);
  width: 140px;

  position: absolute;
  top: 35px;
}

.contacts-page-sort-filter__item {
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}

.contacts-page-sort-filter__header_active {
  background: rgba(197, 202, 227, 0.5);
}

.contacts-page-sort-filter__item_active {
  background: rgba(248, 248, 249, 1);
}
