.home-page-layout {
  position: relative;
  overflow-x: hidden;
}

.home-page-layout__toggler {
  position: absolute;
  z-index: 10;
  top: 25px;
  left: 130px;
}

.home-page-layout__filters {
  position: absolute;
  z-index: 11;
  top: 25px;
  left: 25px;
}

.home-page-layout__draw-panel {
  position: absolute;
  top: 0;
  z-index: 15;
  width: 100%;
}

.home-page-layout__container {
  width: 100%;
  height: calc(100vh - 117px);
}

.home-page-layout__add-route {
  position: absolute;
  padding-top: 60px;
  right: 0;
  z-index: 10;
  width: 100%;
  transform: translateX(+100%);
}

.home-page-layout__add-route_open {
  transform: none;
}
