.home-page-add-route {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-page-add-route__points {
  padding: 0 25px;
  overflow-y: auto;
}

.home-page-add-route__footer {
  margin-top: auto;
}

.home-page-add-route__general-info {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #0E0D5B;
  font-family: 'Open Sans', sans-serif;

  &__label {
    position: relative;
    background-color: #E7F1FF;
    padding: 3px 8px 3px 8px;

    &:after {
      content: '';
      display: block;
      position:absolute;
      width: 10px;
      height: 10px;
      bottom: -10px;
      background-color: #E7F1FF;;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
.home-page-add-route__start-position_img{
  width: 30px;
  height: 30px;
}


.home-page-add-route__start-position{
  display:flex;
  margin-bottom: 18px;
  margin-top: 23px;
  align-items:center;
  margin-left: -4px;
}

.home-page-add-route__start-position_input{
  width: 100%;
  margin-left: 7px;
  margin-right: 7px;
}

.home-page-add-route__start-position_header{
  color: #6A6A99;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}