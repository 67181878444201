@import "../../../styles/letterType";
.select-workspace-create-household-select {
  position: fixed;
  bottom: 20px;
  width: 234px;
  // z-index: 20;
}

.select-workspace-create-household-select__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.select-workspace-create-household-select__span {
  position: absolute;
  // display: block;
  right: 20px;
  top: 20px;
  z-index: 1;
  transform: rotate(270deg) translateX(50%);
  cursor: pointer;
}

.select-workspace-create-household-select__input {
  // position: relative;
  // position: absolute;
  // bottom: -25px;
  // left: 20px;
  // right: 20px;

  background: #fff;
  display: flex;
  align-items: center;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 6px;
  height: 50px;
  // width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
  cursor: pointer;
}

.select-workspace-create-household-select__list-container {
  position: absolute;
  display: none;
  background: #fff;
  z-index: 2;
  top: 34px;
  transform: translateY(-100%);
  right: -237px;
  width: 100%;
  padding: 20px 0;
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
  max-height: 300px;
  min-height: 40px;
  overflow-y: auto;
}

.select-workspace-create-household-select__list {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 36px;
  cursor: pointer;
}

.select-workspace-create-household-select__list:not(:last-child) {
  margin-bottom: 20px;
}

.select-workspace-create-household-select__list-arrow {
  display: none;
}

.select-workspace-create-household-select_active-arrow {
  // transform: rotate(180deg);
  transform: rotate(180deg);
}

.select-workspace-create-household-select_active-check {
  display: block;
}

.select-workspace-create-household-select_active {
  background: rgba(197, 202, 227, 1);
}

.select-workspace-create-household-select_open {
  display: block;
}
