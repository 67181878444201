@import "../../../styles/letterType";

.create-household-checkbox__form-control {
  display: inline;
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(14, 13, 91, 1);
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}

.create-household-checkbox__checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.create-household-checkbox__checkbox::before {
  content: "";
  background: url("../../../assets/fonts/vector.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 1px;
  height: 12px;
  width: 12px;
  display: none;
}

.create-household-checkbox__checkbox:checked::before {
  display: block;
}

.create-household-checkbox_active {
  background: rgba(14, 13, 91, 1);
}
