.home-page-filter-section__calendar {
  padding: 25px 20px;
  background: rgba(249, 249, 250, 1);
}

.home-page-filter-section__footer {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  gap: 20px;
}

.home-page-filter-section__gender {
  padding: 10px 20px;
  background: rgba(249, 249, 250, 1);

  &__item {
    margin-bottom: 5px;
  }
}
