.custom-base-checkbox {
  position: relative;
  height: 42px;
  width: 42px;
  display: inline-block;
}

.custom-base-checkbox__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-base-checkbox__input {
  appearance: none;
  border: 1px solid rgba(197, 202, 227, 1);
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-base-checkbox__input::before {
  content: "";
  background: url("../../../assets/fonts/vector.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 1px;
  height: 12px;
  width: 12px;
  display: none;
}

.custom-base-checkbox__input:checked::before {
  display: block;
}
