.visit-report-update-contact__checkbox {
  margin-bottom: 10px;
}

.visit-report-update-contact__container {
  padding-top: 25px;
  border-top: 1px solid rgba(230, 230, 235, 1);
}

.visit-report-update-contact__header {
  margin-bottom: 25px;
}

.visit-report-update-contact__personal-info {
  margin-bottom: 25px;
}

.visit-report-update-contact__address-panel {
  margin-bottom: 25px;
}

.visit-report-update-contact__address {
  margin-bottom: 20px;
}

.visit-report-update-contact__add-button {
  padding-top: 20px;
  margin-bottom: 40px;
}

.visit-report-update-contact__blue-button {
  margin-bottom: 50px;
}

.visit-report-update-contact__container_closed {
  display: none;
}
