@import "../../../styles/letterType";
.status-panel {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.status-panel__cell {
  display: flex;
  max-width: 210px;
  width: 100%;
}

.status-panel__image {
  padding-right: 10px;
}

.status-panel__title {
  padding-right: 20px;
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 0.5);
}

.status-panel__status {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 1);
}

.status-panel__cell_hidden {
  visibility: hidden;
}
