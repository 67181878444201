@import "../../../styles/letterType";

.update-scheduled-categories {
  width: 30%;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  height: 50%;
  max-height: 60%;

  &__body {
    padding: 25px 35px;
    text-align: center;
    height: calc(100% - 60px - 90px);
    background: rgba(249, 249, 250, 1);
  }

  &__item {
    margin-top: 15px;
  }

  &__footer {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    gap: 20px;
  }
}