@import "../../../styles/letterType";

.layout-login-page__container {
  display: flex;
}

.layout-login-page__form-container {
  display: flex;
  justify-content: center;
  padding: 125px 0;
  background: #fff;
}

.layout-login-page__form-container-inner {
  max-width: 380px;
  width: 100%;
}

.layout-login-page__chevron {
  margin-bottom: 40px;
  cursor: pointer;
}

.layout-login-page__form {
  text-align: center;
}

.layout-login-page__form-title {
  font-family: $type-two;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  margin-bottom: 45px;
}

.layout-login-page__form-email {
  margin-bottom: 40px;
}

.layout-login-page__form-password {
  margin-bottom: 40px;
}

.layout-login-page__forgot {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(77, 148, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.layout-login-page__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.layout-login-page__button {
  margin-bottom: 55px;
  width: 100%;
}

.layout-login-page__line-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.layout-login-page__line {
  width: 70px;
  border: 1px solid rgba(93, 91, 125, 0.3);
}

.layout-login-page__or-subtitle {
  font-family: $type-four;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 20px;
}

.layout-login-page__chabad-button {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.layout-login-page__subtitle {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(14, 13, 91, 1);
}

.layout-login-page__subtitle-link {
  font-family: $type-three;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(77, 148, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.layout-login-page__image-container {
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 50vw;
}

@media (max-width: 500px) {
  .layout-login-page__form-email {
    margin-bottom: 35px !important;
  }

  .layout-login-page__form-password {
    margin-bottom: 35px !important;
  }
}