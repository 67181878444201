@import "../../../styles/letterType";

.route-search__input-container {
  position: relative;
  margin-bottom: 10px;
}

.route-search__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.route-search__icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 1;
}

.route-search__input {
  position: relative;
  border: 1px solid rgba(14, 13, 91, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 50px;
}

.route-search__input:focus {
  outline: none;
}

.route-search__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}
