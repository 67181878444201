.left-town-report__checkbox {
  margin-bottom: 10px;
}

.left-town-report__container {
  padding: 25px 0 30px 0;
  border-top: 1px solid rgba(230, 230, 235, 1);
}

.left-town-report__panel {
  margin-bottom: 30px;
}

.left-town-report__select {
  margin-bottom: 25px;
}

.left-town-report__input {
  margin-bottom: 20px;
}

.left-town-report__telegram-button {
  margin-bottom: 25px;
}

.left-town-report__container_closed {
  display: none;
}
