@import "../../../styles/letterType";

.update-household-layout__sidebar {
  min-width: 300px;
  background: #fff;
  padding: 35px 15px;
  height: fit-content;
}

.update-household-layout__title {
  font-family: $type-one;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(14, 13, 91, 1);
  margin-bottom: 30px;
}

.update-household-layout__button-wrapper:first-child {
  margin-bottom: 10px;
}
