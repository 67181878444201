@import "../../../styles/letterType";
@import "../../../styles/width";

.create-household-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  height: 100%;
}

.create-household-layout__container {
  display: flex;
  gap: 15px;
}

.create-household-layout__header {
  margin-bottom: 65px;
}

.create-household-layout__children-container {
  width: 100%;
}

.create-household-layout__children-container-inner {
  margin-bottom: 30px;
}

.create-household-layout__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.create-household-layout__footer {
  position: absolute;
  margin-top: auto;
  left: -40px;
  bottom: 0px;
  left: 0;
  right: 0;
}

.create-household-layout__next_step {
  display: none;
  justify-content: end;
  background: #6a6a99;
  height: 50px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: #fff;
  padding-right: 32px;
  align-items: center
}

@media only screen and (max-width: 900px) {
  .create-household-layout__next_step {
    display: flex;
  }

  .create-household-layout__footer {
    display: none;
  }
}

@media only screen and (min-width: 901px) {
  .create-household-layout__button {
    margin-bottom: 70px;
  }

}