@import "../../../styles/letterType";

.create-household-multi-select {
  position: relative;
  //z-index: 15;
}

.create-household-multi-select__selected-items {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.create-household-multi-select__selected-items-container {
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 15px;
  background: rgba(246, 247, 252, 1);
  border-radius: 20px;

  color: rgba(14, 13, 91, 1);
  font-family: $type-one;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.create-household-multi-select__selected-items-img {
  height: 16px;
  width: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.create-household-multi-select__input-container {
  position: relative;
  padding-top: 10px;
  //z-index: 10;
}

.create-household-multi-select__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.create-household-multi-select__span {
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 1;
  cursor: pointer;
}

.create-household-multi-select__input {
  position: relative;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
  cursor: pointer;
}

.create-household-multi-select__input:focus {
  outline: none;
}

.create-household-multi-select__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.create-household-multi-select__list-container {
  position: absolute;
  display: none;
  background: #fff;
  z-index: 2;
  width: 100%;
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
  max-height: 300px;
  min-height: 150px;
  overflow-y: auto;
  top: 65px;
}

.create-household-multi-select__list-items {
  background-color: #fff;
}

.create-household-multi-select__list {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 36px;
  cursor: pointer;
}

.create-household-multi-select__list-item-container {
  display: flex;
  align-items: center;
}
.create-household-multi-select__list-item:first-child {
  padding-right: 10px;
}

.create-household-multi-select__list:not(:last-child) {
  margin-bottom: 20px;
}

.create-household-multi-select_active-arrow {
  transform: rotate(180deg);
}

.create-household-multi-select_open {
  display: block;
}
