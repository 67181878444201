.welcome-page-layout {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #fff;

  &__left-side {
    flex-basis: 50%;
    flex-shrink: 0;
    padding-top: 8%;

    &-container {
      margin: 0 auto;
    }

    &-title {
      margin-top: 10%;
      margin-bottom: 12%;
      font-family: 'Open Sans',sans-serif;
      color: #0E0D5B;
      font-style: normal;
      font-weight: 700;
      font-size: 49px;
      line-height: 150%;
    }

    &-buttons-wrapper {

      &.buttons {
        // padding-left: 20%;
        display: flex;
        gap: 30px;
      }
    }
  }

  &__right-side {
    flex-basis: 50%;
    background: url("../../../assets/images/WelcomeScreenBackground.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
}