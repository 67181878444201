.route-page-layout {
  background: #fff;
}

.route-page-layout__container {
  padding: 25px;
  height: calc(100vh - 280px);
  overflow-y: auto;
}

.route-page-layout__header {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.route-page-layout__input {
  flex: 1;
}

.route-page-layout__delete {
  min-width: 180px;
}

.route-page-layout__button {
  margin: 20px 0;
}

.route-page-layout__footer {
  position: absolute;
  right: 0;
  left: 0;
  left: -30px;
  bottom: -50px;
}


.route-page-layout__startPosition {
  display: flex;
  margin-bottom: 60px;
  align-items: center;
  margin-left: -5px;
}

.route-page-layout__startPosition_input {
  width: 100%;
  margin-left: 7px;
}

@media (max-width: 500px) {
  .route-page-layout__container {
    padding: 25px 5px!important;
  }
}