@import "./styles/_colors.scss";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body-background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.light-theme {
  background: $light-theme-background;
  color: $light-theme-text;
}
.dark-theme {
  background: $dark-theme-background;
  color: $dark-theme-text;
}

.pac-container {
  z-index: 2000 !important;
}
