.update-added-person-notification {
  &__modal {
    width: 50%;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
    height: 80%;
    max-height: 80%;

    &__body {
      padding: 25px 35px;
      height: calc(100% - 60px - 90px);
      background: rgba(249, 249, 250, 1);
      overflow-y: auto;
    }

    &__footer {
      position: relative;
    }

    &__button_wrapper {
      width: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}