@import "../../../styles/letterType";

.regular-categories-filter {
  cursor: pointer;
  position: relative;
}

.regular-categories-filter__background {
  display: flex;
}

.regular-categories-filter__background-title {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(37, 36, 94, 1);
  margin-right: 5px;
}

.regular-categories-filter__content-container {
  position: absolute;
  top: 20px;
  width: 320px;
  z-index: 10;
  background: #fff;
  border: 1px solid rgba(230, 230, 235, 0.25);
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

.regular-categories-filter__content-title {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  opacity: 0.4;
  padding-top: 20px;
  padding-left: 20px;
}

.regular-categories-filter__filters li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
