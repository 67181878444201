@import "../../../styles/width";

.create-household-personal-info__panel {
  margin-bottom: 20px;
}

.create-household-personal-info__select {
  // width: 50%;
  width:calc(50% - 10px);
  // padding-right: 10px;
  // margin-bottom: 25px;
}

.create-household-personal-info__input-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  max-width: $max-width;
}

.create-household-personal-info__input {
  flex: 1;
}

.create-household-personal-info__gender-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $max-width;
  // margin-bottom: 30px;
  gap: 20px;
  flex-wrap: wrap;
}

.create-household-personal-info__gender-button-container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 19px;
  
}

.create-household-personal-info__gender-checkbox-container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 35px;
}

.create-household-personal-info__error-text {
  margin-bottom: 30px;
}

@media screen and (max-width: 1040px) {

  .create-household-personal-info__gender-button-container {
    flex-direction: column;
    align-items: start;
     gap: 10px;
     margin-top: 10px;   
  }
}

@media screen and (max-width: 900px) {
  .create-household-personal-info__input-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
    max-width: $max-width;
  }
  .create-household-personal-info__select {
    width: 100%;
  }
  .create-household-personal-info__gender-button-container {

     margin-top: 0px;   
  }
}


