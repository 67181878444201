@import "../../../styles/letterType";

.home-page-saved-point {
  background: rgba(246, 247, 252, 1);
  padding: 20px 25px;
  border-radius: 6px;
}

.home-page-saved-point__container {
  display: flex;
  align-items: center;
}

.home-page-saved-point__container-inner {
  display: flex;
  align-items: center;
  gap: 20px;
}

.home-page-saved-point__double-dots {
  cursor: pointer;
}

.home-page-saved-point__name {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(77, 148, 255, 1);
}

.home-page-saved-point__address {
  margin-bottom: 10px;

  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(106, 106, 153, 1);
}

.home-page-saved-point__coordinates {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(106, 106, 153, 1);
}

.home-page-saved-point__delete-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.home-page-saved-point__menu {
  transform: rotate(90deg);
  background: #fff;
  border: 1px solid rgba(233, 233, 239, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.home-page-saved-point__basket {
  border: 1px solid rgba(233, 233, 239, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  cursor: pointer;
}
