@import "../../../../styles/letterType";

.home-page-filters {
  position: relative;
  height: 32px;
  z-index: 15;
}

.home-page-filters__container {
  box-shadow: 0px 4px 10px rgba(14, 13, 91, 0.16);
  overflow-y: auto;
  background: #fff;
  z-index: 1;
}

.home-page-filters__header {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  max-width: 85px;
  background: #fff;
  border: 1px solid rgba(106, 106, 153, 0.6);
  border-radius: 6px;
  cursor: pointer;
  gap: 5px;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(106, 106, 153, 1);

  &.full-width {
    max-width: 100%;
  }

  &.isDisabled {
    pointer-events: none;
    background-color: #f5f5f5;
  }
}

.home-page-filters__title {
  background: #fff;
}
