@import "../../../styles/letterType";

.saved-point {
  background: rgba(246, 247, 252, 1);
  padding: 20px 25px;
  border-radius: 6px;
}

.saved-point__container {
  display: flex;
  align-items: center;
}

.saved-point__double-dots {
  cursor: pointer;
  margin-right: 40px;
}

.saved-point__name {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(77, 148, 255, 1);
}

.saved-point__address {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
}

.saved-point__take-me-there {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(77, 148, 255, 1);
}

.saved-point__delete-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.saved-point__menu {
  transform: rotate(90deg);
  background: #fff;
  border: 1px solid rgba(233, 233, 239, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

.saved-point__basket {
  border: 1px solid rgba(233, 233, 239, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  cursor: pointer;
}

@media (max-width: 600px) {

  .saved-point__name,
  .saved-point__take-me-there {
    font-size: 14px;

    img {
      display: none;
    }
  }

  .saved-point__address {
    font-size: 10px;
    line-height: 13px;

    img {
      display: none;
    }
  }

  .saved-point__basket {
    display: none;
  }

  .saved-point {
    padding: 10px 0 10px 10px!important;
  }
}