@import "../../../styles/letterType";

.modal-delete-visitor {
  width: 320px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);

  &__body {
    padding: 25px 35px;
    text-align: center;
    background: rgba(249, 249, 250, 1);
  }

  &__title {
    font-family: $type-one;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: center;
    color: rgba(14, 13, 91, 1);
    margin-top: 20px;
  }

  &__footer {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    gap: 20px;
  }
}