.create-household-layout__sidebar {
  min-width: 300px;
  background: #fff;
  padding: 35px 15px;
  max-height: 175px;
}

.create-household-layout__button-wrapper:first-child {
  margin-bottom: 10px;
}
