.address-error-report__checkbox {
  padding-bottom: 10px;
}

.address-error-report__container {
  padding-top: 25px;
  border-top: 1px solid rgba(230, 230, 235, 1);
}

.address-error-report__radio {
  margin-bottom: 20px;
}

.address-error-report__radio:nth-child(2) {
  margin-bottom: 30px;
}

.address-error-report__textarea {
  margin-bottom: 50px;
}

.address-error-report__container_closed {
  display: none;
}

.address-error-report__error-message {
  margin-top: 10px;
  margin-bottom: 10px;
}
