@import "../../../../styles/colors";
@import "../../../../styles/letterType";

.sidebar__tab {
  position: relative;
  margin-top: 5px;
  background: #fff;
  color: rgba(37, 36, 94, 1);
}

.sidebar__tab_active {
  border-radius: 30px 0px 0px 30px;
  height: 48px;
  margin-top: 5px;
  background: rgba(246, 247, 252, 1);

  color: rgba(14, 14, 34, 1);
}

.sidebar__tab-chevron {
  position: absolute;
  right: 40px;
}
.sidebar__tab-chevron_active {
  display: none;
}

.sidebar__tab-top-span_active {
  height: 30px;
  width: 30px;
  background: rgba(246, 247, 252, 1);

  position: absolute;
  right: 0px;
  top: -30px;
  z-index: 1;
}

.sidebar__tab-bottom-span_active {
  height: 30px;
  width: 30px;
  background: rgba(246, 247, 252, 1);

  position: absolute;
  right: 0px;
  bottom: -30px;
  z-index: 1;
}

.sidebar__tab-top-span_active::before {
  content: "";
  display: block;
  height: 48px;
  width: 30px;
  position: absolute;
  background-color: #fff;
  right: 0px;
  top: -18px;
  border-radius: 0 0 30px 0;
  z-index: 2;
}
.sidebar__tab-bottom-span_active::before {
  content: "";
  display: block;
  height: 48px;
  width: 30px;
  position: absolute;
  background-color: #fff;
  right: 0px;
  bottom: -18px;
  border-radius: 0 30px 0 0;
  z-index: 2;
}

.MuiButtonBase-root .sidebar__tab-icon {
  min-width: 43px;
  position: relative;
}

.sidebar__tab-button .sidebar__tab-text {
  opacity: 0.6;
}
.sidebar__tab-icon-wrapper {
  position: absolute;
  top: -18px;
  left: -10px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: none;
}

.sidebar__tab-icon-wrapper_active {
  position: absolute;
  top: -18px;
  left: -10px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: $liner-icons-background;
}

.sidebar__tab-icon-wrapper img {
  position: absolute;
  right: 8px;
  top: 7px;
}
.sidebar__tab-icon-wrapper_active img {
  position: absolute;
  right: 8px;
  top: 7px;
  filter: brightness(3);
}

.sidebar__tab-text .MuiTypography-root {
  font-family: $type-four;
  letter-spacing: 0.2px;
}

.sidebar__tab-text_active .MuiTypography-root {
  font-family: $type-four;
  letter-spacing: 0.2px;
}
