.visit-report-page__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.visit-report-page__report {
  margin-bottom: 20px;
}

.visit-report-page__paginator {
  display: flex;
  justify-content: center;
}
