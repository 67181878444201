.empty-state-past-visits-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15%;

  &__title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 140%;
    color: #0E0D5B;
  }
}