.sidebar-and-appbar-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar-and-appbar-layout__appbar-container {
  display: flex;
  flex-direction: column;
  background: rgba(246, 247, 252, 1);
  //width: calc(100vw - 280px);
  width:100%
}

.sidebar-and-appbar-layout__sidebar-container {
  z-index: 1150;
  width: 280px;
}
