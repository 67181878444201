@import "../../../styles/letterType";

.saved-routes-list-body-item {
  position: relative;
  height: 50px;
  background: #fff;
  padding: 0 46px 0 20px;
}

.saved-routes-list-body-item__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}

.saved-routes-list-body-item__date,
.saved-routes-list-body-item__text {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  width: 110px;
}

.saved-routes-list-body-item__date {
  width: 110px;
}

.saved-routes-list-body-item__action {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 11;
}

.saved-routes-list-body-item:hover {
  border-top: 1px solid rgba(197, 202, 227, 1);
  border-bottom: 1px solid rgba(197, 202, 227, 1);
  background: rgba(246, 247, 252, 0.35);
}


@media (max-width: 600px) {
  .saved-routes-list-body-item__action {
    display: none;
  }

  .saved-routes-list-body-item__empty {
    display: none;
  }

  .saved-routes-list-body-item {
    padding: 0 20px;
    justify-content: space-between;
  }

  .saved-routes-list-body-item__date {
    text-align: center;
  }
}