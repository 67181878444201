@import "../../../styles/letterType";
@import "../../../styles/width";


.create-household-address {
  background: rgba(246, 247, 252, 1);
  padding: 0px 25px;
}

.create-household-address__header {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(197, 202, 227, 1);
}

.create-household-address__header-container {
  display: flex;
  align-items: center;
  flex: 1;
  height: 30px;
}

.create-household-address__title {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-right: 12px;
  border-right: 1px solid rgba(197, 202, 227, 1);

  @media only screen and (min-width: 900px) {
    padding-right: 20px;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
  }
}

.create-household-address__radio-container {
  padding-left: 12px;
  @media only screen and (min-width: 900px) {
    padding-left: 20px;
  }
}

.create-household-address__form-control-radio {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  font-family: $type-one;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  gap: 10px;

  @media only screen and (min-width: 900px) {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }
}

.create-household-address__radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-household-address__radio-checked {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(14, 13, 91, 1);
  margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-household-address__radio::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgba(14, 13, 91, 1);
  display: none;
}

.create-household-address__radio:checked::before {
  display: block;
}

.create-household-address__delete {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border: 1px solid rgba(37, 36, 94, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.create-household-address__select {
  // margin-bottom: 30px;
  width: 100%
  // max-width: 420px;

}

.create-household-address__input {
  margin-bottom: 30px;
  margin-top: 10px;
  width:100%
}
.create-household-address__input-coordinates{
 max-width: calc(#{$max-width}/2 - 35px);
  width: calc(50% - 9px );
  margin-top: 10px;
  margin-bottom: 24px;
}

.create-household-address__textarea {
  padding-bottom: 10px;
  max-width: calc(#{$max-width} - 50px);
 }

.create-household-info__address {
  margin-bottom: 20px;
}


.create-household-address__box-container {
  display: flex;
  
  gap: 20px;
  // margin-bottom: 20px;
  max-width: calc(#{$max-width} - 50px);
  height: 60px;
  margin-bottom: 12px;
}



@media screen and (max-width: 900px) {
  .create-household-address__input-coordinates{
    // margin: 0;
    margin-top: 87px;
    max-width: 100%;
    width:100%;
   }
   
}


