@import "../../../styles/letterType";

.home-page-white-button {
  border: 1px solid rgba(14, 13, 91, 1);
  background: #fff;
  padding: 0 25px;

  font-family: $type-two;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: rgba(14, 13, 91, 1);
  border-radius: 31px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.home-page-white-button_padding {
  padding: 0 15px;
}


.home-page-black-disable{
  background-color: #C5CAE3;
  color:#6A6A99;
  border: 1px solid #C5CAE3;
}