@import "../../../styles/letterType";

.contacts-page-button {
  height: 38px;
  width: 100%;
  font-family: $type-two;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  border-radius: 31px;
  background: rgba(14, 13, 91, 1);
  color: #fff;
  cursor: pointer;
}

.contacts-page-button_white {
  color: rgba(14, 13, 91, 1);
  background: #fff;
  border: 1px solid rgba(14, 13, 91, 1);
}

