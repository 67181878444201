@import "../../../styles/letterType";

.contacts-page-global-actions {
  height: 50px;
  background: rgba(197, 202, 227, 1);
  border: 1px solid rgba(197, 202, 227, 1);
  display: flex;
  align-items: center;
}

.contacts-page-global-actions__selected-title {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding: 0px 15px 0px 15px;
  height: 30px;
  border-right: 1px solid rgba(106, 106, 153, 1);
}

.contacts-page-global-actions__global-select-title {
  font-family: $type-one;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-left: 15px;
  padding-right: 15px;
}

.contacts-page-global-actions__global-button-group {
  display: flex;
  gap: 15px;
}
