.error-modal {
  padding: 25px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
  width: 25%;
  text-align: center;
  min-width: 320px;

  &__close-icon {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: end;
  }

  &__title {
    margin-top: 23px;
    font-size: 19px;
    color: #0E0D5B;
    font-family: 'Poppins', sans-serif;
  }
}