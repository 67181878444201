@import "../../../styles/letterType";
@import "../../../styles/width";

.home-page-input {
  position: relative;
}

.home-page-input__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.home-page-input__input {
  position: relative;
  border: 1px solid rgba(14, 13, 91, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  max-width: $max-width;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;

  &.error {
    border: 1px solid #ff2e2e;
  }
}

// @media (min-width: 1440px) {
//   .home-page-input__input {
//     width: 300px;
//   }
// }
// @media (min-width: 2000px) {
//   .home-page-input__input {
//     width: 40%;
//   }
// }

.home-page-input__input:focus {
  outline: none;
}

.home-page-input__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  // line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  line-height: 50px;
}
