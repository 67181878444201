@import "../../../styles/letterType";

.visit-report-modal {
  max-width: 600px;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.visit-report-modal__header {
  margin-bottom: 45px;
}

.visit-report-modal__backstep {
  height: 60px;
}

.visit-report-modal__backstep__container {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  gap: 20px;
  padding-left: 45px;
  height: 100%;
}

.visit-report-modal__backstep-arrow {
  cursor: pointer;
}

.visit-report-modal__sepper {
  height: 110px;
  padding: 20px 35px;
  background: rgba(246, 247, 252, 1);
}

.visit-report-modal__container {
  padding: 0 35px;
  padding-bottom: 40px;
}
