.visit-report {
  padding: 20px 15px;
  background: #fff;
}

.visit-report__header {
  margin-bottom: 20px;
}

.visit-report__status-panel {
  margin-bottom: 15px;
}

.visit-report__comment {
  margin-bottom: 20px;
}
