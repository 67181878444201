.visit-report-personal-info__panel {
  margin-bottom: 25px;
}

.visit-report-personal-info__select {
  margin-bottom: 20px;
}

.visit-report-personal-info__gender-button {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.visit-report-personal-info__input-first-name {
  margin-bottom: 30px;
}

.visit-report-personal-info__input-last-name {
  margin-bottom: 25px;
}

.visit-report-personal-info__checkbox-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.visit-report-personal-info__error-message {
  margin-bottom: 20px;
}

.visit-report-personal-info__checkbox:first-child {
  margin-bottom: 25px;
}
