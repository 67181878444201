.visit-report-contact-info {
  padding-bottom: 40px;
}

.visit-report-contact-info__panel {
  margin-bottom: 30px;
}

.visit-report-contact-info__phone {
  margin-bottom: 30px;
}
