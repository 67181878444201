@import "../../../styles/letterType";
@import "../../../styles/width";
.phone-number-container {
  background: #f6f7fc;
  display: flex;
  align-items: baseline;
  gap: 20px;
  padding: 13px 25px;
  width: 100%;
  max-width: $max-width;
}

.phone-number-block__container {
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.phone-number-input__type-container {
  flex-basis: 100%;
}
.phone-number-input__input-container {
  position: relative;
  width: 100%;
}

.phone-number-input__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.phone-number-input__end-icon-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.phone-number-input__end-icon:last-child {
  margin-left: 10px;
}

.phone-number-input__delete-icon {
  position: absolute;
  right: -35px;
  top: -33px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  img {
    width: 100%;
  }
}

.phone-number-input__input-container .phone-number-input__input-class {
  position: relative;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 80px;

  @media only screen and (min-width: 900px) {
    padding-left: 110px;
  }
}

.phone-number-input__input-container .phone-number-input__button-class {
  width: 70px;
  background: rgba(246, 247, 252, 1);
  font-family: $type-one;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 900px) {
    width: 100px;
  }
}

.phone-number-input__button-class .selected-flag:hover {
  background-color: rgba(246, 247, 252, 1);
}
.phone-number-input__button-class .selected-flag:focus {
  background-color: rgba(246, 247, 252, 1);
}

.phone-number-input__absolute-number{
  position: absolute;
  left: 24px;
  top: 12px;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;  
}