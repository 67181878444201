@import "../../../styles/letterType";

.contacts-list {
  border: 1px solid rgba(230, 230, 235, 1);
  border-radius: 4px;
}
.contacts-list__loader {
  position: absolute;
  background-color: #f6f7fca9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.contacts-list__search-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 55px;
  padding-left: 20px;
  padding-right: 40px;
}

.contacts-list__input-container {
  display: flex;
  align-items: center;

  font-family: $type-two;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(84, 97, 137, 1);
}

.contacts-list__input-container span {
  padding: 0 5px;
}

.contacts-list__info-panel {
  display: flex;
  height: 42px;
  background: rgba(223, 224, 236, 0.2);
  padding-left: 20px;
  @media only screen and (max-width: 900px) {
    overflow: scroll;
  }
}

.contacts-list__cell {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  h5 {
    font-size: 12px;
    line-height: 18px;
    color: rgba(14, 13, 91, 1);
    font-family: $type-two;
  }
}

.name {
  width: 70%;
  min-width: 90px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
}

.scheduled-categories {
  min-width: 185px;
  width: 100%;
}

.regular-categories {
  min-width: 140px;
  width: 100%;
}

.contact {
  min-width: 150px;
}

.duch {
  min-width: 110px;
}

.history {
  min-width: 110px;
}

.basket {
  min-width: 65px;
  cursor: pointer;
  
  &.isDisabled {
    cursor: default;
    pointer-events: none;
  }
}

.contacts-list__list-container {
  background-color: #fff;
  position: relative;
  @media only screen and (max-width: 900px) {
    overflow: scroll;
  }
}

.contacts-list__list {
  display: flex;
  height: 50px;
  padding-left: 20px;
  background: #fff;
}


.contacts-list__cell-image{
  width: 26px;
  height:26px;

  &.isDisabled {
    pointer-events: none;
  }
}