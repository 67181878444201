@import "../../styles/letterType";

.error {
  background: #fff;
}

.error__container {
  padding-left: 210px;
  padding-top: 78px;
  min-height: 100vh;
  background: url("../../assets/images/error.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100vh;
  background-color: "#000";
  background-position: right;
}

.error__image {
  margin-bottom: 164px;
}

.error__button {
  width: 190px;
}

.error-page-button {
  height: 58px;
  width: 190px;
  font-family: $type-two;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  border-radius: 31px;
  cursor: pointer;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  border: 1px solid rgba(14, 13, 91, 1);
}

.error__form-title {
  font-family: $type-two;
  font-size: 61px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  margin-bottom: 45px;
  max-width: 500px;
}
