@import "../../../styles/letterType";

.registr-page-first-step__container {
  display: flex;
}

.registr-page-first-step__form-container {
  display: flex;
  justify-content: center;
  padding: 125px 0;
  width: 50vw;
  background: #fff;
}

.registr-page-first-step__form-container-inner {
  max-width: 380px;
  width: 100%;
}

.registr-page-first-step__chevron {
  margin-bottom: 40px;
  cursor: pointer;
}

.registr-page-first-step__form {
  text-align: center;
}

.registr-page-first-step__form-title {
  font-family: $type-two;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  margin-bottom: 45px;
}

.registr-page-first-step__form-email {
  margin-bottom: 40px;
}

.registr-page-first-step__form-password {
  margin-bottom: 40px;
}

.registr-page-first-step__forgot {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(77, 148, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.registr-page-first-step__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.registr-page-first-step__button {
  margin-bottom: 55px;
  width: 100%;
}

.registr-page-first-step__line-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.registr-page-first-step__line {
  width: 70px;
  border: 1px solid rgba(93, 91, 125, 0.3);
}

.registr-page-first-step__or-subtitle {
  font-family: $type-four;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 20px;
}

.registr-page-first-step__chabad-button {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.registr-page-first-step__subtitle {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(14, 13, 91, 1);
}

.registr-page-first-step__subtitle-link {
  font-family: $type-three;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(77, 148, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.registr-page-first-step__image-container {
  background: url("../../../assets/images/loginImage.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  min-width: 50vw;
}
