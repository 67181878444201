@import "../../../styles/letterType";

.input-component {
  position: relative;
}

.input-component__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.input-component__input {
  position: relative;
  border: 1px solid rgba(14, 13, 91, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
}

.input-component__input:focus {
  outline: none;
}

.input-component__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  // line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  line-height: 50px;
}
//.home-page-input-before::before{
//  content: "Псевдоелемент";
//  display: block;
//  color: red;
//  position: absolute;
//  z-index: 100;
//  right: 0;
//}
.home-page-input__padding{
  padding-right: 45px;
}