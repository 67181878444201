.switch-workspace-modal {
  padding: 40px 79px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
  width: 40%;
  text-align: center;

  &__close-icon {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: end;
  }

  &__title {
    margin-bottom: 45px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: #0e0d5b;
  }
  &__wrapper-description {
  }
}

@media (max-width: 1400px) {
  .switch-workspace-modal {
    width: 40%;
    padding: 40px 30px;
  }
}
@media (max-width: 900px) {
  .switch-workspace-modal {
    width: 50%;
    padding: 40px 20px;
  }
}
@media (max-width: 500px) {
  .switch-workspace-modal {
    width: 70%;
    padding: 30px 10px;
  }
}
