.login-page-chabad-button {
  height: 36px;
  background: rgba(77, 148, 255, 0.15);
  padding: 0 20px;
  border-radius: 6px;
  gap: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
}
