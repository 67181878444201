@import "../../../styles/letterType";

.contacts-page-global-button {
  height: 28px;
  background: rgba(14, 13, 91, 1);
  border-radius: 16px;
  padding: 0 15px;
  color: #fff;

  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
}
