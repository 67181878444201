.alert-notification {
  min-height: 81px;
  width: 350px;
  background-color: #fff;
  box-shadow: 0 22px 40px rgba(11, 10, 68, 0.18);
  border-radius: 10px;
  padding: 14px 10px;
  pointer-events: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left-side {
      display: flex;
      align-items: center;
    }

    &__time {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #25245E;
      opacity: 0.5;
      margin-left: 8px;
    }
  }

  &__body {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-top: 14px;

    &__description {
      color: #25245E;
    }
    &__name {
      color: #4D94FF;
      text-decoration: underline;
    }
  }
}