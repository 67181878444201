@import "../../../styles/letterType";

.create-household-panel {
  width: 100%;
  height: 42px;
  background: rgba(197, 202, 227, 0.5);
  display: flex;
  align-items: center;
  padding-left: 20px;

  font-family: $type-one;
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
}

.create-household-panel__item:first-child {
  margin-right: 15px;
}
