@import "../../../styles/letterType";
@import "../../../styles/width";

.create-household-contact-info__panel {
  margin-bottom: 30px;
}

.create-household-contact-info__container-inner {
  display: flex;
  flex: 1;
  gap: 20px;
  margin-bottom: 12px;
}

.create-household-contact-info__phone-input {
  flex: 1;
}

.create-household-contact-info__email-container {
  // display: flex;
  // gap: 20px;
  margin-bottom: 12px;
  // max-width: calc(( $max-width / 2 ) - 10px);
  // max-width: calc(50% - 40px);
  max-width: calc(#{$max-width}/2 + 14px);
  width: calc(50% + 16px);

  background: #f6f7fc;
  padding: 13px 25px;

  @media (max-width: 900px) {
  width:100%;
   max-width: 100%;
  }
}

.create-household-contact-info__container-inner-white{
  background-color:#fff;
}

