@import "../../../styles/letterType";
.visit-report-select {
  position: relative;
  padding-top: 10px;
  z-index: 60;
}

.visit-report-select__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.visit-report-select__span {
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 1;
  cursor: pointer;
}

.visit-report-select__input {
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  border: 1px solid rgba(14, 13, 91, 1);

  &.error {
    border: 1px solid #ff2e2e;
  }

  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
  cursor: pointer;
}

.visit-report-select__list-container {
  position: absolute;
  display: none;
  background: #fff;
  z-index: 2;
  border-radius: 6px;
  border: 1px solid rgba(230, 230, 235, 1);
  top: 65px;
  width: 100%;
  padding: 7px 0;
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

// LIST

.visit-report-select__list {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 36px;
  cursor: pointer;
}

.visit-report-select__list:not(:last-child) {
  margin-bottom: 20px;
}

.visit-report-select__list-arrow {
  display: none;
}

.visit-report-select_active-arrow {
  transform: rotate(180deg);
}

.visit-report-select_active-check {
  display: block;
}

.visit-report-select_active {
  border-top: 1px solid rgba(230, 230, 235, 1);
  border-bottom: 1px solid rgba(230, 230, 235, 1);
  background: rgba(246, 247, 252, 1);
}

.visit-report-select_open {
  display: block;
}

.visit-report-select_less {
  z-index: 40;
}
