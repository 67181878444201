@import "../../../styles/letterType";

.notification-item {
  position: relative;
  background: #f9faff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  border-radius: 6px;
  margin: 15px 0;
  &__marked {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    background: #EE4E4E;
    border-radius: 50%;
    cursor: pointer;
  }
  &__delete {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 1px solid #C5CAE3;
    width: 19px;
    height: 19px;
    background: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    img {
      max-width: 100%;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }
  &__reaction {
    margin-right: 15px;
  }
  &__content {
    h3 {
      font-family: $type-one;
      color: rgba(14, 13, 91, 1);
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
  }
  &__time {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
  }
  &__arrow {
    margin-left: 15px;
    cursor: pointer;
  }
  &_unread{
    background: #f5f6ff;
  }
}
