.saved-routes-layout {
  position: relative;
  height: calc(100vh - 170px);
  padding: 0 25px;
}

.saved-routes-layout__header {
  margin-bottom: 55px;
}

.saved-routes-layout__container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.saved-routes-layout__map {
  min-width: 440px;
  height: 200px;
}

.saved-routes-layout__children {
  width: 100%;
}
