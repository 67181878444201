@import "../../../styles/letterType";

.create-household-categories {
  background: #fff;
  // min-height: 940px;
  margin-bottom: 60px;
}

.create-household-categories__header {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(197, 202, 227, 1);
  padding: 0 20px;
  margin-bottom: 15px;
}

.create-household-categories__img-container {
  padding-right: 15px;
}

.create-household-categories__title {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
}

.create-household-categories__container {
  padding: 0 15px 150px 15px;

  @media only screen and (min-width: 900px) {
    padding: 0 15px;
  }
}

.create-household-categories__multi-select {
  margin-bottom: 30px;
}

.create-household-categories__selects {
  padding-bottom: 20px;
}
