@import "../../../styles/letterType";

.visit-report-phone-number-input__input-container {
  position: relative;
}

.visit-report-phone-number-input__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
  border-radius: 6px;
}

.visit-report-phone-number-input__end-icon-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.visit-report-phone-number-input__end-icon:last-child {
  margin-left: 10px;
}

.visit-report-phone-number-input__input-container
  .visit-report-phone-number-input__input-class {
  position: relative;
  border: 1px solid rgba(14, 13, 91, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 110px;

  &.error {
    border: 1px solid #ff2e2e;
  }
}

.visit-report-phone-number-input__input-container
  .visit-report-phone-number-input__button-class {
  width: 100px;
  background: rgba(246, 247, 252, 1);
  border-top: 1px solid rgba(14, 13, 91, 1);
  border-left: 1px solid rgba(14, 13, 91, 1);
  border-bottom: 1px solid rgba(14, 13, 91, 1);
  font-family: $type-one;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;

  &.error {
    border-top: 1px solid #ff2e2e;
    border-left: 1px solid #ff2e2e;
    border-bottom: 1px solid #ff2e2e;
  }
}

.visit-report-phone-number-input__button-class .selected-flag:hover {
  background-color: rgba(246, 247, 252, 1);
}
.visit-report-phone-number-input__button-class .selected-flag:focus {
  background-color: rgba(246, 247, 252, 1);
}
