@import "../../../styles/letterType";

.sidebar {
  width: 280px;
  height: 100%;
  background: #fff;
}

.sidebar__logo-wrapper {
  padding-left: 69px;
  padding-top: 16px;
}

.sidebar__tab-wrapper {
  height: 100%; //
  padding-left: 26px;
  padding-top: 50px;
}

.sidebar__divider {
  border-bottom: 1px solid rgba(14, 14, 34, 1);
  width: 100%;
  opacity: 0.1;
}

.sidebar__tab-wrapper .sidebar__title-general {
  font-family: $type-four;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  padding-left: 6px;
  opacity: 0.4;
}

.sidebar__tab-wrapper .sidebar__ul-general {
  padding-top: 14px;
}

.sidebar__tab-wrapper .sidebar__title-about {
  font-family: $type-four;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  padding-left: 14px;
  padding-top: 29px;
  opacity: 0.4;
}

.sidebar__tab-wrapper .sidebar__ul-about {
  padding-top: 16px;
}
