@import "../../../styles/letterType";

.home-page-red-button {
  border: 1px solid rgba(238, 78, 78, 1);
  background: rgba(238, 78, 78, 0.06);
  padding: 0 25px;

  font-family: $type-two;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: rgba(238, 78, 78, 1);
  border-radius: 31px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.home-page-red-button_padding {
  padding: 0 15px;
}
