@import "../../../styles/letterType";

.menu-actions {
  position: relative;
  width: 30px;
  margin: 0 auto;
}

.menu-actions__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(233, 233, 239, 1);
}

.menu-actions__image-container:hover {
  background: rgba(246, 247, 252, 1);
}

.menu-actions__img {
  transform: rotate(90deg);
}


.menu-actions__text {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
}

.menu-actions__text {
  &.danger {
    color: rgba(238, 78, 78, 1);
  }
}

