@import "../../../../../styles/letterType";

.adding-report-modal-children__select {
  margin-bottom: 40px;
}

.adding-report-modal-children__select:nth-child(6) {
  margin-bottom: 35px;
}

.adding-report-modal-children__calendar {
  margin-bottom: 40px;
}

.adding-report-modal-children__checkbox {
  margin-bottom: 10px;
}
