@import "../../../styles/letterType";

.create-household-footer {
  display: flex;
  justify-content: end;
  background: rgba(106, 106, 153, 1);
  height: 50px;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: #fff;
}

.create-household-footer__container {
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 0;
  margin-left: 25px;
}

.create-household-footer__title {
  padding-right: 10px;
  cursor: pointer;
}

.create-household-footer__icon {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 10px;
  cursor: pointer;
}

.create-household-footer__label {
  padding-left: 10px;
  cursor: pointer;
}
