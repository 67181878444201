@import "../../../styles/letterType";

.home-section-header {
  background: #fff;
  padding: 0 20px 0 20px;
  cursor: pointer;
}

.home-section-header__container {
  border-bottom: 1px solid rgba(230, 230, 235, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 15px 0 15px 0;
}

.home-section-header__title {
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  opacity: 0.4;
}

.home-section-header__img_open {
  transform: rotate(180deg);
}
