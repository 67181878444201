@import "../../../styles/letterType";

.contacts__modal {
}

.contacts-modal__container {
  max-width: 350px;
  width: 100%;
  max-height: 375px;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

.contacts-modal__title-container {
  h3 {
    font-family: $type-two;
    color: rgba(14, 13, 91, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(230, 230, 235, 1);
  height: 59px;
}

.contacts-modal__img-container {
  cursor: pointer;
}

.contacts-modal__buttons-group {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding-bottom: 40px;
  gap: 20px;
}
