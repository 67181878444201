@import "../../../styles/letterType";

.households-page-icon-button {
  height: 40px;
  background: rgba(14, 13, 91, 1);
  width: 100%;
  cursor: pointer;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #fff;
  border-radius: 24px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 10px;

  &:disabled {
    background-color: #C5CAE3;
    color: #6A6A99;
    cursor: default;
  }
}
