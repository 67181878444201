@import "../../../styles/letterType";

.update-household-info {
  position: relative;
}

.update-household-info__container {
  display: flex;
}

.update-household-info__header {
  margin-bottom: 65px;
}

.update-household-info__children-container {
  width: 100%;
}

.update-household-info__children-container-inner {
  margin-bottom: 30px;
}

.update-household-info__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}

.update-household-info__footer {
  position: absolute;
  left: -40px;
  bottom: 0px;
  left: 0;
  right: 0;
}
