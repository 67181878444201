@import "../../../styles/letterType";

.home-page-draw-panel {
  height: 60px;
  padding: 0 25px;
  background: rgba(77, 148, 255, 1);

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-page-draw-panel__buttons-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.home-page-draw-panel__button {
  cursor: pointer;
}
