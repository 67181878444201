.home-page-save-route-name-modal {
  background: #fff;
  max-width: 600px;
  width: 100%;
}

.home-page-save-route-name-modal__container {
  padding: 60px 40px;
}

.home-page-save-route-name-modal__footer {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(230, 230, 235, 1);
}

.home-page-save-route-name-modal__footer-container {
  max-width: 240px;
  width: 100%;
}
