@import "../../../styles/letterType";

.route-search-list {
  background: #fff;
  border: 1px solid rgba(230, 230, 235, 1);
  box-shadow: 0px 4px 10px rgba(14, 13, 91, 0.16);
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
}

.route-search-list__panel {
  height: 36px;
  background: rgba(197, 202, 227, 0.2);
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid rgba(230, 230, 235, 1);

  font-family: $type-five;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: rgba(106, 106, 153, 1);
  margin-bottom: 10px;
}

.route-search-list__panel-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 60px;
}

.route-search-list__item {
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 36px;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  cursor: pointer;
}

.route-search-list__item-name {
  color: rgba(14, 13, 91, 1);
}

.route-search-list__item-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 30px;
  color: rgba(106, 106, 153, 1);
}

.route-search-list__item:hover {
  background: rgba(197, 202, 227, 0.5);
}
