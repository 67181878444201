@import "../../../styles/letterType";

.visit-report-telegram-button {
  height: 40px;
  width: 100%;
  background: rgba(231, 241, 255, 1);
  border-radius: 6px;
  cursor: pointer;
}

.visit-report-telegram-button__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  font-family: $type-five;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: center;
  color: rgba(77, 148, 255, 1);
}

.visit-report-telegram-button__label {
  padding-top: 2px;
}

.visit-report-telegram-button-white{
  background-color: #fff;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}