@import "../../../styles/letterType";

.layout-header {
  display: flex;
  //align-items: center;
  //justify-content: space-between;
  padding-top: 25px;
}

.layout-header__container {
  display: flex;
}

.layout-header__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  h2 {
    font-family: $type-two;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0.02em;
    color: rgba(14, 13, 91, 1);
  }
}

.layout-header__title-container {
  display: flex;
  align-items: center;
}
.layout-header__arrow-left {
  width: 50px;
}

.layout-header a {
  font-family: $type-one;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  text-decoration: none;
}

.layout-header__link {
  color: rgba(157, 167, 199, 1);
}

.layout-header__link-container {
  min-width: 230px;
  height: 15px;
  display: flex;
  //justify-content: end;
}

.layout-header__container-inner {
  display: flex;
  align-items: center;
  gap: 5px;
}

.layout-header__link_active {
  color: rgba(14, 13, 91, 1);
}
