@import "../../../styles/letterType";

.create-household-input {
  position: relative;
}

.create-household-input__end-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.create-household-input__span {
  font-family: $type-five;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(77, 148, 255, 1);
  margin-left: 10px;
}

.create-household-input__input {
  position: relative;
  border: 1px solid rgba(197, 202, 227, 1);
  border-radius: 6px;
  height: 50px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-left: 20px;
}

.create-household-input__input:focus {
  outline: none;
}

.create-household-input__input::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  margin: auto;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  line-height: 50px;
}
