.visit-report-children-footer {
  height: 90px;
  //padding-top: 20px;
  padding-right: 25px;
  border-top: 1px solid rgba(230, 230, 235, 1);
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;

  &__button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4D94FF;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .visit-report-children-footer {
    margin-bottom: 50px !important;
  }
}