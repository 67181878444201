.alert-notifications {
  position: fixed;
  top: 10%;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: end;
  padding: 1.5rem 1rem;
  z-index: 99999;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  //&__button-wrapper {
  //  width: 100%;
  //  display: flex;
  //  justify-content: end;
  //}
  //
  //&__clear-all-button {
  //  background: #FFFFFF;
  //  padding: 4px 14px;
  //  box-shadow: 0 4px 14px rgba(77, 148, 255, 0.12), 0 2px 8px rgba(14, 13, 91, 0.08);
  //  border-radius: 12px;
  //  font-family: 'Open Sans', sans-serif;
  //  font-style: normal;
  //  font-weight: 600;
  //  font-size: 12px;
  //  line-height: 16px;
  //  color: #0E0D5B;
  //  cursor: pointer;
  //}
}