@import "../../../styles/letterType";

.home-page-draw-toggler {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  max-width: 85px;
  background: #fff;
  border: 1px solid rgba(106, 106, 153, 0.6);
  border-radius: 6px;
  cursor: pointer;
  gap: 5px;

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(106, 106, 153, 1);
}
