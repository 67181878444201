@import "../../../styles/letterType";

.radio-button__form-control {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  gap: 10px;
}

.radio-button__radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-button__radio::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgba(14, 13, 91, 1);
  display: none;
}

.radio-button__radio:checked::before {
  display: block;
}
