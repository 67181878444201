@import "../../../styles/letterType";
.visit-report-textarea {
  position: relative;
}

.visit-report-textarea__label {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(14, 13, 91, 1);
  background: #fff;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 15px;
  padding: 0 5px;
}

.visit-report-textarea__textarea {
  position: relative;
  border: 1px solid rgba(14, 13, 91, 1);
  border-radius: 6px;
  height: 100px;
  width: 100%;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding: 15px 20px;
  resize: none;
}

.visit-report-textarea__textarea:focus {
  outline: none;
}

.visit-report-textarea__textarea::placeholder {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 0.4);
  margin: auto;
  position: absolute;
}
