@import "../../../styles/letterType";

.login-page-button {
  cursor: pointer;
  max-width: 380px;
  width: 100%;
  height: 58px;
  border-radius: 31px;
  background: rgba(14, 13, 91, 1);

  font-family: $type-two;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.login-page-button_disabled {
  background: rgba(197, 202, 227, 1);
  cursor: auto;
}
