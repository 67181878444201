@import "../../../styles/letterType";

.visit-report-gender-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(246, 247, 252, 1);
  width: 100%;
  border-radius: 12px;
  font-family: $type-one;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 25px 0 50px;
  color: rgba(106, 106, 153, 1);
  cursor: pointer;
}

.visit-report-gender-button__start-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.visit-report-gender-button__end-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: rgba(14, 13, 91, 1);
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visit-report-gender-button_active {
  color: rgba(14, 13, 91, 1);
  border: 1px solid rgba(14, 13, 91, 1);
}
