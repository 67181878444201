@import "../../../styles/letterType";

.home-page-save-route-modal {
  max-width: 390px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

.home-page-save-route-modal__footer {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  gap: 20px;
}

.team-save-route-modal__footer {
  padding-bottom: 34px;
  width: 233px;
  margin: 0 auto;
 margin-top: 25px;
}

.team-save-route-modal__container{
  padding-right: 40px;
  padding-left: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.team-form{
  margin-top: 50px;
}

.team-form_input{
  margin-bottom: 33px;
}

.team-form_input-error{

  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(238, 78, 78, 1);
}