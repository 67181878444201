.successful-creation-household-modal {
  padding: 30px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
  width: 20%;
  text-align: center;
  min-width: 400px;

  &__title {
    margin-top: 23px;
    font-size: 19px;
    color: #0E0D5B;
    font-family: 'Poppins', sans-serif;
  }
}