@import "../../../styles/letterType";

.visit-report-button {
  height: 40px;
  border-radius: 24px;
  background: rgba(14, 13, 91, 1);
  color: #fff;
  &:hover {
    background-color: #4D94FF;
    transition: .3s;
  }

  &:disabled {
    background-color: #C5CAE3;
    color: #6A6A99;
  }

  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  padding: 10px 15px;
  cursor: pointer;
}

.visit-report-button__image {
  padding-right: 5px;

  &.disabled {
    filter: brightness(0.5);
  }
}
