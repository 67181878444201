@import "../../../styles/letterType";

.route-nav-method-modal {
  max-width: 400px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

.route-nav-method-modal__container {
  padding: 30px 20px;
}

.route-nav-method-modal__item {
  height: 56px;
  background: rgba(246, 247, 252, 1);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
}

.route-nav-method-modal__item:not(:last-child) {
  margin-bottom: 20px;
}

.route-nav-method-modal__img {
  margin-right: 25px;
}
