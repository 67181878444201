.change-name-category-input-container {
  display: flex;
  align-items: center;
  gap: 15px;

  &__icon-box {
    height: 26px;
    width: 26px;
    padding: 6.5px;
    border: 1px solid #E9E9EF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}