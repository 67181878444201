@import "../../../styles/letterType";

.visit-report-person-header {
  display: flex;
  align-items: center;
  flex: 1;
  height: 40px;
  background: rgba(197, 202, 227, 0.5);
  padding: 0 20px;
}

.visit-report-person-header__title {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  padding-right: 20px;
  border-right: 1px solid rgba(197, 202, 227, 1);
}

.visit-report-person-header__radio-container {
  padding-left: 20px;
}

.visit-report-person-header__form-control-radio {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  gap: 10px;
}

.visit-report-person-header__radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid rgba(14, 13, 91, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.visit-report-person-header__radio::before {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgba(14, 13, 91, 1);
  display: none;
}

.visit-report-person-header__radio:checked::before {
  display: block;
}

.visit-report-person-header__action-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
}

.visit-report-person-header__basket {
  cursor: pointer;
}

.visit-report-person-header__arrow {
  margin-left: 10px;
}
