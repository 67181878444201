@import "../../../styles/letterType";

.switch-button {
  display: inline-block;
  background: rgba(197, 202, 227, 0.3);
  border-radius: 20px;
  padding: 2px;
  height: 40px;
}

.switch-button__container {
  display: flex;
  height: 100%;
  gap: 20px;
}

.switch-button__half {
  display: flex;
  align-items: center;
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  border-radius: 20px;
  height: inherit;
  padding: 0 25px;
  cursor: pointer;
  color: #0E0D5B;
}

.switch-button__half_active {
  border: 1px solid rgba(197, 202, 227, 1);
  background: #fff;
  color: #4D94FF;
}
