.visit-report-address {
  background: rgba(246, 247, 252, 1);
  padding-bottom: 20px;
}

.visit-report-address__header {
  height: 60px;
  padding: 0 20px;
  margin-bottom: 30px;
}

.visit-report-address__header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid rgba(197, 202, 227, 1);
  color: rgba(14, 13, 91, 1);
}

.visit-report-address__title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.visit-report-address__img {
  cursor: pointer;
}

.visit-report-address__container {
  padding: 0 20px;
}

.visit-report-address__select {
  margin-bottom: 30px;
}

.visit-report-address__address {
  margin-bottom: 20px;
}

.visit-report-address__autocomplete {
  position: relative;
  z-index: 1;
}

.visit-report-address__telegram {
  margin-bottom: 30px;
}

.visit-report-address__coordinates {
  margin-bottom: 30px;
}
