@import "../../../styles/letterType";

.home-page-save-route-modal {
  max-width: 390px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(14, 13, 91, 0.16);
}

.home-page-save-route-modal__footer {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  gap: 20px;
}
