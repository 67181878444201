@import "../../../styles/letterType";

.visit-report__filter-header {
  h3 {
    font-family: $type-two;
    color: rgba(14, 13, 91, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  border-bottom: 1px solid rgba(230, 230, 235, 1);
  height: 60px;
}

.visit-report__filter-header-img {
  cursor: pointer;
}
