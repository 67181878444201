@import "../../../styles/letterType";

.report-comment {
  display: flex;
  align-items: center;
}

.report-comment__img {
  padding-right: 10px;
}

.report-comment__title {
  padding-right: 20px;
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 0.5);
}

.report-comment__text {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
}
