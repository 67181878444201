@import "../../../styles/letterType";

.notification {
  position: relative;
  margin: 15px 0;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__reaction {
    margin-right: 15px;
  }
  &__title {
    h3 {
      font-family: $type-one;
      color: rgba(14, 13, 91, 1);
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
  }
  &__time {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
  }
  &__notes {
    margin-top: 30px;
    &-header {
      padding: 10px 0;
      font-family: $type-one;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: rgba(14, 13, 91, 0.7);
      border-bottom: 1px solid #E6E6EB;
    }
    &-body {
      margin-top: 10px;
      font-family: $type-one;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #25245E;
    }
  }
  &__info {
    margin-top: 30px;
    font-family: $type-one;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #25245E;
    &-button {
      margin-top: 30px;
    }
  }
  &__new-address {
    margin-top: 30px;
    &-row {
      margin-bottom: 25px;
    }
  }
  &__buttons {
    margin-top: 50px;
    width: 100%;
    display: flex;
    button {
      max-width: 50%;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  &__button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    button {
      max-width: 50%;
    }
  }
}
