@import "../../../../../styles/letterType";

.contacts__import-children {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  min-height: 200px;
}

.contacts__import-children-image-second {
  position: absolute;
  top: 90px;
}
.contacts__import-change-container {
  display: flex;
  flex-direction: column;
}

.contacts__import-children-title-first {
  font-family: $type-two;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(14, 13, 91, 1);
  padding-top: 50px;
}

.contacts__import-children-label {
  cursor: pointer;
}

.contacts__import-children-title-second {
  font-family: $type-two;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(106, 106, 153, 1);
  opacity: 0.6;
}

.contacts__import-children-input {
  border-radius: 4px;
  background: rgba(246, 247, 252, 1);
  height: 40px;
  margin-top: auto;
  max-width: 310px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.contacts__import-children-input img {
  cursor: pointer;
}
