@import "../../../styles/letterType";

.home-page-black-button {
  background: rgba(14, 13, 91, 1);
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $type-two;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #fff;
  border-radius: 31px;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.home-page-black-button_padding {
  padding: 0 15px;
}

.home-page-black-disable{
  background-color: #C5CAE3;
  color:#6A6A99
}
