@import "../../../styles/letterType";

.my-team-list__container {
  border-right: 1px solid rgba(197, 202, 227, 1);
  border-left: 1px solid rgba(197, 202, 227, 1);
  border-bottom: 1px solid rgba(197, 202, 227, 1);
  border-radius: 0px 0px 4px 4px;
}

.my-team-list__footer {
  margin-top: 40px;
  margin-bottom: 70px;
  display: flex;
  justify-content: end;
}

.my-team-list__table {
  th, td {
    font-family: $type-one;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(14, 13, 91, 1);
    padding: 10px 15px;
  }
  th {
    background: rgba(246, 247, 252, 1);
    border-top: 1px solid rgba(197, 202, 227, 1);
    font-family: $type-two;
    line-height: 24px;
  }
}


.my-team-list-table-cell__status {
  padding: 3px 12px;
  background-color: #FFF1E4;
  color: #FF5C00;
}

.my-team-list-table-cell__status-active {
  background-color: #EFFFE4;
  color:#49A907;
}

.my-team-list-table-cell_center {
  text-align: center !important;
}
