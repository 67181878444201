@import "../../../styles/letterType";

.visit-report-panel {
  width: 100%;
  height: 42px;
  background: rgba(197, 202, 227, 0.5);
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 6px;
}

.visit-report-panel__item:first-child {
  margin-right: 15px;
}
