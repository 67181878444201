.route-page-stepper {
}

.route-page-stepper__step {
  position: relative;
  display: flex;
  align-items: center;
  
}

.route-page-stepper__step-content {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.route-page-stepper__step-component {
  border-radius: 6px;
  width: 100%;
  cursor: grab;
}

.route-page-stepper__step-connector {
  position: absolute;
  bottom: 30px;
  left: 80px;
  text-align: center;
}
