@import "../../../styles/letterType";

.scheduled-visit-report {
  padding: 25px 20px;
  border-radius: 4px;
  background: #fff;
}

.scheduled-visit-report__warning-panel {
  max-width: 405px;
  margin-bottom: 25px;
}

.scheduled-visit-report__title {
  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(14, 13, 91, 1);
  margin-bottom: 25px;
}
