@import "../../../styles/letterType";

.home-page-save-optimize-children {
  padding: 25px 35px;
  background: rgba(249, 249, 250, 1);
}

.home-page-save-optimize-children__logo {
  text-align: center;
  margin-bottom: 25px;
}

.home-page-save-optimize-children__title {
  font-family: $type-one;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(14, 13, 91, 1);
}
