@import "../../../styles/letterType";

.visit-report-panel {
  width: 100%;
  height: 42px;
  background: rgba(246, 247, 252, 1);
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: rgba(14, 13, 91, 1);
}

.visit-report-panel__item:first-child {
  margin-right: 15px;
}
