@import "../../../styles/letterType";

.create-household-button {
  height: 40px;
  width: 140px;
  cursor: pointer;
  border-radius: 24px;
  border: 2px solid rgba(14, 13, 91, 1);
  background: rgba(14, 13, 91, 1);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.create-household-button__start-icon {
  display: flex;
  align-items: center;
}

.create-household-button__img {
  height: 12px;
  width: 12px;
}

.create-household-button__title {
  font-family: $type-one;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: center;
}
