@import "../../../styles/letterType";

.saved-routes-list-header {
  background: rgba(246, 247, 252, 1);
  border: 1px solid rgba(197, 202, 227, 1);
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saved-routes-list-header__text,
.saved-routes-list-header__text__actions {
  font-family: $type-two;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(14, 13, 91, 1);
}

@media (max-width: 600px) {
  .saved-routes-list-header__text__actions {
    display: none;
  }
}