@import "../../../styles/letterType";

.manage-categories-modal {
  //width: 40%;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  //height: 60%;
  //max-height: 60%;

  &__body {
    background: rgba(249, 249, 250, 1);
    padding: 20px 20px 0 20px;
    overflow-y: auto;
    height: calc(100% - 60px);
  }

  &__delete-confirmation-modal {
    padding: 30px;
    background: rgba(249, 249, 250, 1);
    text-align: center;
    height: calc(100% - 60px - 90px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &__title {
      margin-top: 23px;
      font-size: 19px;
      color: #0E0D5B;
      font-family: 'Poppins', sans-serif;
    }
  }

  &__successful-modal {
    padding: 30px;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(14, 13, 91, 0.16);
    width: 20%;
    text-align: center;
    &__title {
      margin-top: 23px;
      font-size: 19px;
      color: #0E0D5B;
      font-family: 'Poppins', sans-serif;
    }
  }

  &__subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #6A6A99;
    border-bottom: 1px solid #E6E6EB;
    padding-bottom: 8px;
  }

  &__button-add {
    background: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding: 20px;
    border-top: 1px solid #E6E6EB;
    width: 100%;
    cursor: pointer;
    margin-top: 21px;
    color: #0E0D5B;
  }

  &__input-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__save-button {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4D94FF;
  }

  &__category-item-box {
    padding-top: 16px;
    &.items {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.item {
      color: #0E0D5B;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
  }

  &__title {
    font-family: $type-one;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: center;
    color: rgba(14, 13, 91, 1);
    margin-top: 20px;
  }



  &__footer {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    gap: 20px;
  }
}