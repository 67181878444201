.home-page-route-footer {
  height: 80px;
  background: #fff;
  border-top: 1px solid rgba(230, 230, 235, 1);
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 0 20px;
}
