.route-search-modal {
  background: #fff;
  max-width: 600px;
  width: 100%;
}

.route-search-modal__container {
  padding: 50px 40px 30px 40px;
}

.route-search-modal__footer {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(230, 230, 235, 1);
}
