@import "../../../styles/letterType";

.custom-link-button {
  text-decoration: none;
}

.custom-link-button__button {
  height: 22px;
  font-family: $type-one;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: none;
  border-radius: 4px;
  color: rgba(77, 148, 255, 1);
  background: rgba(10, 108, 255, 0.1);
  padding: 0 10px;
  gap: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;

  &.disabled {
    background-color: #C5CAE3;
    color: #6A6A99;
    cursor: default;
  }
}

.custom-link-button .custom-link-button__img {
  height: 12px;
  width: 12px;
}
