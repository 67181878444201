@import "../../../styles/letterType";

.delete-route-button {
  height: 50px;
  border-radius: 4px;
  background: rgba(246, 247, 252, 1);
  width: 100%;
  cursor: pointer;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(238, 78, 78, 1);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
