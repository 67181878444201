@import "../../../styles/letterType";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #fff;
  opacity: 0.6;
  margin-top: auto;

  font-family: $type-three;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.02em;
}
