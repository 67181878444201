@import "../../../styles/letterType";

.visit-report-header {
  border-bottom: 1px solid rgba(197, 202, 227, 1);
}

.visit-report-header__container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.visit-report-header__title {
  padding-right: 20px;
  font-family: $type-one;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 0.5);
}

.visit-report-header__date {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: $type-one;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(37, 36, 94, 1);
}

.visit-report-header__menu {
  margin-left: auto;
  cursor: pointer;
}
